<template>
	<NuxtLink
		:to="props.linkType === 'externalLink' ? props.href : internalRoute"
		:target="
			props.blank && props.linkType === 'externalLink' ? '_blank' : ''
		"
		:rel="
			props.blank && props.linkType === 'externalLink' ? 'noopener' : ''
		"
		class="link"
		><slot></slot
	></NuxtLink>
</template>

<script setup>
import { defineProps, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const props = defineProps({
  linkType: {
    type: String,
    default: () => 'externalLink',
  },
  href: {
    type: String,
    default: () => '',
  },
  blank: {
    type: Boolean,
    default: () => false,
  },
  route: {
    type: String,
    default: () => '',
  },
  slug: {
    type: String,
    default: () => '',
  }
})

const route = useRoute()
const router = useRouter()

const internalRoute = computed(() => {
	return {
		name: props.route ?? 'index',
		params: props.slug ? { slug: props.slug } : {},
	}
})
</script>


<style scoped>
.Navigation_Item .router-link-active {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-content: center;
}
.Navigation_Item .router-link-active::after {

  @media (min-width: token(width.md)) {
	content:url('~/assets/icons/Cross.svg');
	font-size: 100%;
	position: absolute;
	transform-origin: center;
	transform: translate(0, 95%);
  }
}
</style>
