<template>
  <nav class="Navigation text-lg">
    <ul v-if="mainStore.siteNav?.navMain" class="Navigation_List">
      <li
        v-for="link in mainStore.siteNav?.navMain"
        :key="link.id"
        class="Navigation_Item text-base text_uppercase"
      >
        <ElementsTextLink
          :link-type="link.linkType"
          :href="link.href"
          :blank="link.blank"
          :route="link.route"
          :slug="link.slug"
          :data-content="link.title"
          @click="toggleMenu"
        >
          {{ link.title }}
        </ElementsTextLink>
      </li>
    </ul>
  </nav>
</template>

<script setup>
const mainStore = useMainStore()

const toggleMenu = () => {
  mainStore.toggleMenu()
}
</script>

<style scoped>
.Navigation {
  width: max-content;
}

.Navigation_List {
  width: max-content;
  list-style: none;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-evenly;
  align-items: flex-start;
  gap: var(--base-margin);
  @media (min-width: token(width.md)) {
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
  }
}

.Navigation_Item {
  padding: 0.1rem 0;
  @media (min-width: token(width.md)) {
    text-align: right;
    padding: 0.3rem 0 0 0;
  }
}

.Navigation_Item a {
  @media (min-width: token(width.md)) {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
  }
}

.Navigation_Item:hover a:not(.router-link-active)::after {
  @media (min-width: token(width.md)) {
    opacity: 1;
    font-weight: 600;
  }
}

/* .Navigation_Item:hover a.router-link-active::after {
  @media (min-width: token(width.md)) {
    position: absolute;
    opacity: 1;
    transform-origin: center; */
    /* content: url('~/assets/icons/Arrow.svg') !important; */
    /* content: url('~/assets/icons/Cross.svg') !important;
    transform: translate(0, 75%) rotate(180deg);
    font-weight: 400;
    color: var(--rgb-offblack);
  }
} */

.Navigation_Item a:not(.router-link-active)::after {
  @media (min-width: token(width.md)) {
    position: absolute;
    opacity: 0;
    transform-origin: center;
    content: url('~/assets/icons/Cross.svg') !important;
    transform: translate(0%, 75%) rotate(180deg);
    font-weight: 400;
    color: var(--rgb-offblack);
  }
}
</style>