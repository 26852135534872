
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as aboutUps1O5LIrtAMTN7g3TIo6BD1e4NWh967_45BizD8tWvWoMeta } from "/opt/build/repo/web/pages/about.vue?macro=true";
import { default as indexMIScuOvc4NX_45oHf90z8zi_45pmLo39Q6MTAjSRhstAGAEMeta } from "/opt/build/repo/web/pages/index.vue?macro=true";
import { default as _91slug_93y0TaBEroe5h07rp0JeizFbMIemVZ2NQrLtL6YJ4hyAwMeta } from "/opt/build/repo/web/pages/[slug].vue?macro=true";
import { default as contactHN9qGMnZJOnjqK8oDApSVvpBaG_m3cUPTtVQ240GbYgMeta } from "/opt/build/repo/web/pages/contact.vue?macro=true";
import { default as servicesqGQfoad5aiOWZ07VX36t0nXiAaTU6VEoSC6NQ68TnLQMeta } from "/opt/build/repo/web/pages/services.vue?macro=true";
import { default as indexLlw9enS0mMpDqrJF51nEq1EC2QWUb6fIpB7U5MLK12oMeta } from "/opt/build/repo/web/pages/projects/index.vue?macro=true";
import { default as sustainabilityCu5pFaAX1UTYoTrTVBQiLrx91tHT29ddivPzlw20z0wMeta } from "/opt/build/repo/web/pages/sustainability.vue?macro=true";
import { default as _91slug_930fNWVoxVUHP9wnm8kC7EDtc0acly6OZ3p4dBeb5dqREMeta } from "/opt/build/repo/web/pages/projects/[slug].vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/opt/build/repo/web/pages/about.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/build/repo/web/pages/index.vue")
  },
  {
    name: "slug",
    path: "/:slug()",
    meta: _91slug_93y0TaBEroe5h07rp0JeizFbMIemVZ2NQrLtL6YJ4hyAwMeta || {},
    component: () => import("/opt/build/repo/web/pages/[slug].vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/opt/build/repo/web/pages/contact.vue")
  },
  {
    name: "services",
    path: "/services",
    component: () => import("/opt/build/repo/web/pages/services.vue")
  },
  {
    name: "projects",
    path: "/projects",
    component: () => import("/opt/build/repo/web/pages/projects/index.vue")
  },
  {
    name: "sustainability",
    path: "/sustainability",
    component: () => import("/opt/build/repo/web/pages/sustainability.vue")
  },
  {
    name: "projects-slug",
    path: "/projects/:slug()",
    meta: _91slug_930fNWVoxVUHP9wnm8kC7EDtc0acly6OZ3p4dBeb5dqREMeta || {},
    component: () => import("/opt/build/repo/web/pages/projects/[slug].vue")
  }
]