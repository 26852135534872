import unhead_tatWuHKDSKdI_qjkgLdurKYYPRB9Ha_0ylWDSCE21Jo from "/opt/build/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.13_db0@0.3.1_eslint@8.57.1_ioredis@_b6d3cfbd3a27efa0b825f956d1709490/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_JFSUcP8lPShhkaYncLky3V0vGfCNJjCy3bo15NZMytY from "/opt/build/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.13_db0@0.3.1_eslint@8.57.1_ioredis@_b6d3cfbd3a27efa0b825f956d1709490/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_EVvWrc7ereEzDVkmpL_Mfbql9dZwNdKVdFiNefqdL1s from "/opt/build/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.13_db0@0.3.1_eslint@8.57.1_ioredis@_b6d3cfbd3a27efa0b825f956d1709490/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_xcHeJ5X0q8wieUTH_71VrFHGNTJNdKmTHvdprgGFqS0 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.13_db0@0.3.1_eslint@8.57.1_ioredis@_b6d3cfbd3a27efa0b825f956d1709490/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_4PnoCJaODlrSQiM9dHEHgu9WJF4lH661iA8tXCrzhJw from "/opt/build/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.13_db0@0.3.1_eslint@8.57.1_ioredis@_b6d3cfbd3a27efa0b825f956d1709490/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_iURaq5wUgNzeCAndvYzAtNnKFAieKYln1QVoD6KSLAc from "/opt/build/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.13_db0@0.3.1_eslint@8.57.1_ioredis@_b6d3cfbd3a27efa0b825f956d1709490/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_qUzjEj8CEwpweJXv1UKms1h4UYi27Fseq4Vf5ncWvTU from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.4.11_magicast@0.3.5_typescript@5.8.2_vue@3.5.13_typescript@5.8.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/opt/build/repo/web/.nuxt/components.plugin.mjs";
import prefetch_client_7oGEZ_DD90m1dL3tvLLsr_dnEcoydLnKiHEEHXHDYW0 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.13_db0@0.3.1_eslint@8.57.1_ioredis@_b6d3cfbd3a27efa0b825f956d1709490/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_DQY3F6hH3dcrqq6ceWe2mo2eFOg4zrRNaJdblxrT8PU from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import sanity_image_url_ZIFsHHvXKOHe7aBmxIirX3fZBbaIDh1_pTKfI41jwr0 from "/opt/build/repo/web/plugins/sanity-image-url.js";
import sanity_UVGjbsmU6He_QKSqt9goWNcJjGQleK6GPV_g1uufNqA from "/opt/build/repo/web/plugins/sanity.js";
export default [
  unhead_tatWuHKDSKdI_qjkgLdurKYYPRB9Ha_0ylWDSCE21Jo,
  router_JFSUcP8lPShhkaYncLky3V0vGfCNJjCy3bo15NZMytY,
  payload_client_EVvWrc7ereEzDVkmpL_Mfbql9dZwNdKVdFiNefqdL1s,
  navigation_repaint_client_xcHeJ5X0q8wieUTH_71VrFHGNTJNdKmTHvdprgGFqS0,
  check_outdated_build_client_4PnoCJaODlrSQiM9dHEHgu9WJF4lH661iA8tXCrzhJw,
  chunk_reload_client_iURaq5wUgNzeCAndvYzAtNnKFAieKYln1QVoD6KSLAc,
  plugin_vue3_qUzjEj8CEwpweJXv1UKms1h4UYi27Fseq4Vf5ncWvTU,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_7oGEZ_DD90m1dL3tvLLsr_dnEcoydLnKiHEEHXHDYW0,
  plugin_DQY3F6hH3dcrqq6ceWe2mo2eFOg4zrRNaJdblxrT8PU,
  sanity_image_url_ZIFsHHvXKOHe7aBmxIirX3fZBbaIDh1_pTKfI41jwr0,
  sanity_UVGjbsmU6He_QKSqt9goWNcJjGQleK6GPV_g1uufNqA
]